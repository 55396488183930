<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <span v-if="isGarageUpdate && garage.displayInfo">
              <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
              |
              <span
                class="c-blue-text tw-cursor-pointer"
                @click="undoGarageDetails"
                >Report Summary</span
              >
              | <span class="title">{{ garage.garageName }} Garage</span>
            </span>

            <span v-else>
              <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
              | <span class="title">Report Summary</span>
            </span>
          </h1>
        </v-col>
      </v-row>

      <v-container class="mt-8">
        <v-row>
          <v-col class="mx-auto" cols="12" md="12">
            <v-card>
              <div class="tw-px-2 md:tw-px-6">
                <card-title icon="mdi-car" :is-image="false">{{
                  getTitle
                }}</card-title>
              </div>

              <div
                class="
                  tw-px-2
                  md:tw-px-6
                  tw-pt-6 tw-flex tw-items-center tw-gap-6
                "
                v-if="!garage.displayInfo"
              >
                <div class="tw-flex tw-items-center tw-gap-2">
                  <p class="mb-0 tw-font-semibold">Report From:</p>

                  <v-menu v-model="menu1" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="fromDate"
                        clearable
                        placeholder="Enter Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="fromDate = null"
                        class="p-0 tw-w-40"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      class="tw-w-full"
                      color="primary"
                      v-model="fromDate"
                      @change="menu = false"
                      :max="new Date().toISOString().substring(0, 10)"
                    />
                  </v-menu>
                </div>

                <div class="tw-flex tw-items-center tw-gap-2">
                  <p class="mb-0 tw-font-semibold">To:</p>

                  <v-menu v-model="menu2" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="toDate"
                        clearable
                        placeholder="Enter Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="toDate = null"
                        class="p-0 tw-w-40"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      class="tw-w-full"
                      v-model="toDate"
                      @change="menu = false"
                      :max="new Date().toISOString().substring(0, 10)"
                      color="primary"
                    />
                  </v-menu>
                </div>

                <v-btn
                  color="primary"
                  class="generate tw-mb-3"
                  @click="generateReport"
                  :disabled="!fromDate || !toDate"
                  ><v-icon left> mdi-file-document-outline </v-icon
                  >Generate</v-btn
                >
              </div>

              <div class="tw-px-2 md:tw-px-6 tw-pt-6" v-else>
                <h3 class="tw-text-xl c-gray-text">
                  {{ garage.garageName }} Garage
                </h3>
              </div>

              <div class="tw-px-2 md:tw-px-6 tw-pt-6">
                <assessment-centre-listing
                  v-if="!isGarageUpdate"
                  :data="assessmentCentreListing"
                  :addMakeField="this.category !== 'VEHICLES_BOOKED'"
                  :loading="isLoading"
                ></assessment-centre-listing>

                <garage-listing
                  v-else
                  :garageReports="garage.listing"
                  :displayGarageView="garage.displayInfo"
                  :loading="isLoading"
                  @set-garage-details="setGarageName"
                  @options-event="setOptionsEvent"
                ></garage-listing>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="mt-8" v-if="!garage.displayInfo">
        <v-row>
          <v-col class="mx-auto" cols="12" md="12">
            <div class="tw-flex tw-justify-end">
              <v-btn
                color="primary"
                :disabled="
                  !assessmentCentreListing.length && !garage.listing.length
                "
                :loading="isDownloading"
                @click="downloadExcel"
              >
                <v-icon left> mdi-arrow-down-circle-outline </v-icon>Download
                Excel
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import {
  BIDDING_REPORT_CATEGORY,
  BIDDING_REPORT_GARAGE_CATEGORY,
} from '@/utils/const'
import { getFromAndToDate } from '@/utils/time'
import CardTitle from '@/components/CardTitle'
import axios from 'axios'
import moment from 'moment'
import FileSaver from 'file-saver'

export default {
  components: {
    CardTitle,
    AssessmentCentreListing: () =>
      import('@/components/dashboard/AssessmentCentreListing'),
    GarageListing: () => import('@/components/dashboard/GarageListing'),
  },
  data: () => ({
    category: null,
    fromDate: null,
    toDate: null,
    isLoading: false,
    menu1: false,
    menu2: false,
    assessmentCentreListing: [],
    garage: {
      displayInfo: false,
      garageName: null,
      listing: [],
      pagination: {
        page: 1,
        limit: 10,
      },
    },
    isDownloading: false,
  }),
  computed: {
    isGarageUpdate() {
      return !!BIDDING_REPORT_GARAGE_CATEGORY.includes(
        this.$route.query.category
      )
    },
    getTitle() {
      let title
      if (!this.isGarageUpdate) {
        title = this.formatString()
      } else {
        const titles = {
          ALL: 'Vehicles in Garages',
          VEHICLE_RELEASED: 'Vehicles Released',
          REPAIR_IN_PROGRESS: 'Repairs In Progress',
        }

        title = titles[this.category]
      }
      return `${title} Reports`
    },
  },
  methods: {
    setCategory() {
      if (
        !BIDDING_REPORT_CATEGORY.includes(this.$route.query.category) &&
        !BIDDING_REPORT_GARAGE_CATEGORY.includes(this.$route.query.category)
      ) {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: 'Invalid category',
          variant: 'error',
        })
        this.$router.push({ name: 'Dashboard' })
      } else this.category = this.$route.query.category
    },
    setDefaultDates() {
      const { from, to } = getFromAndToDate()
      this.fromDate = from
      this.toDate = to
    },
    async fetchReport() {
      this.isLoading = true

      if (this.isGarageUpdate) {
        await axios
          .get('/motor-assessment/api/reports/garage/list', {
            params: {
              page: this.garage.pagination.page,
              limit: this.garage.pagination.limit,
              status: this.category,
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then(({ data }) => {
            if (data.success) this.garage.listing = data.data
          })
          .catch((err) => {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: err.message ?? 'Failed to retrieve report',
              variant: 'error',
            })
          })
          .finally(() => (this.isLoading = false))
      } else {
        await axios
          .get(
            `/motor-assessment/api/reports/historic/${this.category}/${this.fromDate}/${this.toDate}`
          )
          .then(({ data }) => {
            if (data.success) this.assessmentCentreListing = data.data
          })
          .catch((err) => {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: err.message ?? 'Failed to retrieve report',
              variant: 'error',
            })
          })
          .finally(() => (this.isLoading = false))
      }
    },
    generateReport() {
      if (moment(this.fromDate).isAfter(this.toDate)) {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: 'Invalid date range',
          variant: 'error',
        })

        return
      }

      this.fetchReport()
    },
    formatString() {
      return (
        this.category &&
        this.category
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      )
    },
    setGarageName(garageName) {
      this.garage.garageName = garageName
      this.garage.displayInfo = true
    },
    undoGarageDetails() {
      this.garage.garageName = null
      this.garage.displayInfo = false
    },
    setOptionsEvent(val) {
      if (
        this.garage.pagination.page != val.page ||
        this.garage.pagination.limit != val.itemsPerPage
      ) {
        this.garage.pagination.page = val.page
        this.garage.pagination.limit = val.itemsPerPage

        if (this.isGarageUpdate && !this.garage.displayInfo) this.fetchReport()
      }
    },
    async downloadExcel() {
      this.isDownloading = true

      const url = this.isGarageUpdate
        ? '/motor-assessment/api/reports/garage/list/excel'
        : `/motor-assessment/api/reports/historic/excel/${this.category}/${this.fromDate}/${this.toDate}`

      const config = this.isGarageUpdate
        ? {
            params: {
              page: this.garage.pagination.page,
              limit: this.garage.pagination.limit,
              status: this.category,
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
            responseType: 'blob',
          }
        : { responseType: 'blob' }

      await axios
        .get(url, config)
        .then((res) => {
          const contentDisposition = res.headers['content-disposition']
          const fileNameRegex = /filename="(.+?)"/
          const fileNameMatch = contentDisposition.match(fileNameRegex)
          const fileName =
            fileNameMatch && fileNameMatch[1]
              ? fileNameMatch[1]
              : `${this.category}_REPORT_AS_AT_${this.fromDate}_TO_${this.toDate}.xlsx`

          FileSaver.saveAs(res.data, fileName)
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg ?? 'Failed to download file',
            variant: 'error',
          })
        })
        .finally(() => (this.isDownloading = false))
    },
  },
  beforeMount() {
    this.setCategory()
    this.setDefaultDates()
    this.fetchReport()
  },
}
</script>

<style>
.v-input {
  margin: 0;
  padding: 10px 0 0 0;
}
button.generate {
  border-radius: 40px !important;
}
</style>
